import React from 'react';

export function ImgArrowLeft({className, style}) { return <svg className={className+" SVG_arrowLeft"} style={style} viewBox="-7 9 16 16" > <polygon points="-3.4,17 3.9,9.7 5.4,11.1 -0.5,17 5.4,22.9 3.9,24.3 	"/> </svg>  }
export function ImgArrowRight({className, style}) { return <svg className={className+" SVG_arrowRight"} style={style} viewBox="-7 9 16 16" > <polygon points="5.4,17 -1.9,24.3 -3.4,22.9 2.5,17 -3.4,11.1 -1.9,9.7 	"/> </svg>  }
export function ImgBell({className, style}) { return <svg className={className+" SVG_bell"} style={style} viewBox="0 0 50 50" > <path d="M41.7,16.7C41.7,7.5,34.2,0,25,0S8.3,7.5,8.3,16.7c0,4.6-1.5,8.2-3.2,11C2.8,31.5,0,32.6,0,35.6 c0,4.2,11.2,7.7,25,7.7s25-3.4,25-7.7c0-3-2.8-4.1-5.1-7.9C43.2,24.9,41.7,21.4,41.7,16.7z"/> <circle cx="25" cy="43.3" r="6.7"/> </svg>  }
export function ImgBronze({className, style}) { return <svg className={className+" SVG_bronze"} style={style} viewBox="0 0 100 100" > <circle cx="50" cy="50" r="50"/> <polygon points="50,8.1 62.9,31.4 89.1,36.5 70.9,56 74.2,82.4 50,71.2 25.9,82.4 29.2,56 11,36.5 37.1,31.4 "/> </svg>  }
export function ImgChallenge({className, style}) { return <svg className={className+" SVG_challenge"} style={style} viewBox="-359 161 240 240" > <path d="M-590,322"/> <path d="M-323.7,301.7c-3.7-3.7-9.6-3.7-13.3,0l-12.1,12.1c-3.7,3.7-3.7,9.6,0,13.3l62.8,62.8c3.7,3.7,9.6,3.7,13.3,0 l12.1-12.1c3.7-3.7,3.7-9.6,0-13.3L-323.7,301.7z"/> <path d="M-154.2,201.3c-31.5-31.5-74.8-39.3-106.1-8.1c-5,5-9.1,10.5-12.5,16.3l31.7,31.7c7.8,7.8,10,19.2,6.6,29 l13.1,13.1c10.8,10.8,28.3,10.8,39.1,0l7.3-7.3l11.3,11.3l-7.3,7.3c-17,17-44.7,17-61.7,0l-11.3-11.3c-11,8.3-26.7,7.5-36.7-2.5 l-17.3-17.3l11.3-11.3l17.3,17.3c4.7,4.7,12.3,4.7,17,0c4.7-4.7,4.7-12.3,0-17l-27.3-27.3v0c-16.8-16.8-45.7-4.8-45.7,19l0,33.2 l88.8,88.8c30.2-7.8,55.7-24,90.5-58.8C-114.8,276.1-122.6,232.8-154.2,201.3z"/> </svg>  }
export function ImgCloud({className, style}) { return <svg className={className+" SVG_cloud"} style={style} viewBox="-359 161 240 240" > <path d="M-231,161"/> <path d="M-143.1,269.9c2.9-5.2,4.6-11.1,4.6-17.5c0-19.5-15.8-35.3-35.3-35.3c-4.7,0-9.2,0.9-13.3,2.6 c-7.2-22-27.9-37.9-52.3-37.9c-26.7,0-49,19.1-54,44.4c-3.6-1.6-7.6-2.5-11.9-2.5c-16.4,0-29.7,13.3-29.7,29.7 c0,8.1,3.2,15.4,8.5,20.8c-5.3,4-8.7,10.3-8.7,17.4c0,12,9.7,21.7,21.7,21.7c2.1,0,4.1-0.3,6-0.9c6.4,18.4,23.8,31.6,44.4,31.6 c7.7,0,14.9-1.8,21.3-5.1c10.1,12.9,25.7,21.2,43.3,21.2c25.5,0,46.9-17.3,53.1-40.8c0.5,0,1.1,0.1,1.6,0.1 c13.6,0,24.7-11,24.7-24.7C-119,281.2-129.7,270.2-143.1,269.9z"/> <circle cx="-338.8" cy="360" r="20.2"/> </svg>  }
export function ImgCross({className, style}) { return <svg className={className+" SVG_cross"} style={style} viewBox="-84 86 10 10" > <rect x="-80" y="85.5" transform="matrix(0.7071 0.7071 -0.7071 0.7071 41.2084 82.5146)" width="2" height="11"/> <rect x="-84.5" y="90" transform="matrix(0.7071 0.7071 -0.7071 0.7071 41.2084 82.5146)" width="11" height="2"/> </svg>  }
export function ImgCrown({className, style}) { return <svg className={className+" SVG_crown"} style={style} viewBox="0 0 120 120" > <polygon points="100,90 20,90 120,35.9 "/> <polygon points="100,90 20,90 0,35.9 "/> <polygon points="92.3,90 27.7,90 60,0 "/> <rect x="20" y="100" width="80" height="20"/> </svg>  }
export function ImgDropdown({className, style}) { return <svg className={className+" SVG_dropdown"} style={style} viewBox="-82 86 16 10" > <polygon points="-74,95.4 -81.3,88.1 -79.9,86.6 -74,92.5 -68.1,86.6 -66.7,88.1 	"/> </svg>  }
export function ImgDropup({className, style}) { return <svg className={className+" SVG_dropup"} style={style} viewBox="0 4 16 10" > <polygon points="8,4.6 15.3,11.9 13.9,13.4 8,7.5 2.1,13.4 0.7,11.9 	"/> </svg>  }
export function ImgEyedrop({className, style}) { return <svg className={className+" SVG_eyedrop"} style={style} viewBox="-471 275 20 20" > <path d="M-452,276c-1.3-1.3-3.6-1.3-4.9,0l-0.9,0.9l0,0c-0.6-0.6-1.7-0.6-2.4,0l-1.8,1.8c-0.7,0.7-0.7,1.7,0,2.4l0,0l-6.1,6.1 c-1.8,1.8-2,2.5-2,3.4c0,0.3,0,0.5-0.3,1.1c-0.3,0.6-1.1,1.9,0,2.9c0.4,0.4,0.8,0.5,1.2,0.5c0.7,0,1.3-0.4,1.7-0.6 c0.5-0.3,0.8-0.3,1.1-0.3c0.9,0,1.6-0.2,3.4-2l6.1-6.1c0.3,0.3,0.7,0.4,1.1,0.4c0,0,0,0,0,0c0.5,0,0.9-0.2,1.2-0.5l1.8-1.8 c0.7-0.7,0.7-1.7,0-2.4l0,0l0.9-0.9C-450.7,279.6-450.7,277.3-452,276z M-464.5,290.7c-1.4,1.4-1.7,1.4-2,1.4c-0.5,0-1.1,0.1-2,0.6 c-0.1,0-0.2,0.1-0.4,0.2c0.1-0.1,0.2-0.3,0.2-0.4c0.5-0.9,0.6-1.4,0.6-2c0-0.3,0-0.7,1.4-2l6.1-6.1l2.2,2.2L-464.5,290.7z M-453.5,279.5l-2.3,2.3l1.2,1.2l-1.3,1.3l-4.5-4.5l1.3-1.3l1.2,1.2l2.3-2.3c0.6-0.6,1.5-0.6,2.1,0 C-452.9,278-452.9,278.9-453.5,279.5z"/> </svg>  }
export function ImgFacebook({className, style}) { return <svg className={className+" SVG_facebook"} style={style} viewBox="0 0 160 160" > <path d="M114.4,80.6c-0.7,0-1.3,0-1.9,0c-6.1,0-12.2,0-18.3,0c-1.3,0-1.8-0.3-1.8-1.7c0.1-4.6,0-9.3,0.1-13.9 c0.1-7,4.5-11.5,11.5-11.8c3.3-0.2,6.7-0.1,10-0.1c1.2,0,1.6-0.4,1.6-1.6c0-5.5,0-11,0-16.5c0-0.8,0-1.4-1-1.6 c-8.2-1.2-16.4-2.3-24.6-0.7C76.9,35.1,69,44.9,68,57.4c-0.6,6.9-0.3,13.9-0.4,20.9c0,2.3,0,2.3-2.3,2.3c-5.4,0-10.8,0-16.2,0 c-1.4,0-1.8,0.4-1.8,1.8c0.1,6.5,0.1,13,0,19.5c0,1.5,0.6,1.8,1.9,1.8c5.5-0.1,11,0,16.5,0c1.4,0,1.9,0.3,1.9,1.8 c0,17.4,0,34.8,0,52.3c0,1.9,0,1.9-2,1.5c-13.7-2.6-25.9-8.2-36.6-17.1C12.7,128.4,3.2,111,0.6,90c-2-16.8,1-32.8,9.2-47.6 C19.5,24.6,34,12.2,53.1,5.3c10.9-4,22.1-5.3,33.6-4.4c17.4,1.4,32.8,7.9,45.9,19.4C147,32.9,156,48.8,158.9,67.8 c3.7,24.1-2.3,45.7-17.9,64.3c-12.3,14.7-28.1,23.6-47,27.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.1,0-0.3-0.1-0.6-0.1 c0-0.6-0.1-1.2-0.1-1.8c0-17.3,0-34.5,0-51.8c0-2,0-2,2.1-2.1c5,0,10.1,0,15.1,0c0.9,0,1.5-0.1,1.6-1.2c1.1-7,2.2-14.1,3.3-21.1 C114.6,81.2,114.5,81,114.4,80.6z"/> </svg>  }
export function ImgGlyph({className, style}) { return <svg className={className+" SVG_glyph"} style={style} viewBox="-359 161 240 240" > <path d="M-120,293.1h-95c0,2.4,0.1,4.2,0.7,9c2.2,15.6,9.5,23.4,22,23.4c9.6,0,16.5-4.1,18.6-11.3 c1.1-3.6,1.2-4.9,1.3-6.3l51.2,4.2c-0.1,2.3-0.3,4.6-1.7,9.5c-4.1,14.2-10.5,22.4-21.6,31.7c-13.5,11.3-29.7,17-48.4,17 c-21.7,0-39.7-7.4-54.2-22.3c-1.6-1.6-5.8-6.7-5.8-6.7s-4.2,5.4-5.7,7.1c-12.7,14.7-29.3,22-49.7,22c-16.5,0-29-5.1-37.6-15.2 c-8.8-10.3-13.1-22.2-13.1-35.9c0-17.8,6.5-32.2,19.6-43.4c11.4-9.7,39.3-19.4,73.1-21.9c-0.3-12.9-7.6-21.6-20.6-21.6 c-9.6,0-19.7,6-21.9,18.6l-45.7-3.2c6-37.6,31.8-56.3,65.2-56.3c18.3,0,33.4,5.2,43.9,14c1.5,1.2,5.2,4.7,5.2,4.7s3.6-3.6,5-4.9 c10.6-9.1,27.3-13.7,39.6-13.7c23.7,0,42.4,8.4,56.1,25.3c13.7,16.8,20.6,38,20.6,63.6c0,1.3-0.1,3-0.2,5.1 C-119.4,288.9-119.5,290.8-120,293.1z M-264.3,295.5c0-2.6,0-9,0-9c-3.7,0-5.6,0.3-10,1.4c-16.8,4.5-25.3,13-25.3,25.5 c0,5,1.3,8.9,4,11.7c2.7,2.9,5.9,4.3,9.8,4.3C-271.5,329.5-264.3,318.1-264.3,295.5C-264.3,295.6-264.3,295.6-264.3,295.5z M-174.5,260c0-4-0.2-6.3-0.5-9c-1.9-13.7-8.4-20.5-19.6-20.5c-11.2,0-17.7,6.8-19.8,20.4c-0.4,2.8-0.6,5.8-0.6,9.1H-174.5z"/> </svg>  }
export function ImgGold({className, style}) { return <svg className={className+" SVG_gold"} style={style} viewBox="0 0 100 100" > <circle cx="50" cy="50" r="50"/> <polygon points="50,8.1 62.9,31.4 89.1,36.5 70.9,56 74.2,82.4 50,71.2 25.9,82.4 29.2,56 11,36.5 37.1,31.4 "/> </svg>  }
export function ImgHalloffame({className, style}) { return <svg className={className+" SVG_halloffame"} style={style} viewBox="9 -9 50 50" > <path d="M34-2C22.1-2,12.5,7.6,12.5,19.5S22.1,41,34,41s21.5-9.6,21.5-21.5S45.9-2,34-2z M39.7,30.3l-6.2-3.2l-6.2,3.2 c-1.1,0.5-2.3-0.3-2.2-1.5l0.9-7l-4.8-4.8c-0.9-0.9-0.4-2.3,0.8-2.6l6.8-1.4l3.2-6C32.7,6,34.2,6,34.8,7l3.2,6l6.8,1.4 c1.2,0.2,1.6,1.7,0.8,2.6l-4.8,4.8l0.9,7C42,29.9,40.8,30.8,39.7,30.3z"/> <path d="M50.2,1h-33c-1.9,0-3.5-1.6-3.5-3.5v-3c0-1.9,1.6-3.5,3.5-3.5h33c1.9,0,3.5,1.6,3.5,3.5v3 C53.6-0.6,52.1,1,50.2,1z"/> </svg>  }
export function ImgHeart({className, style}) { return <svg className={className+" SVG_heart"} style={style} viewBox="-359 161 240 240" > <path d="M-172.2,195.9c-29.8-4.2-56.3,12.5-66.8,37.5c-10.5-25-37-41.8-66.8-37.5c-28,4-50.1,27.1-52.9,55 c-3.3,32.5,18.7,60.5,48.8,67c26.8,5.8,49.6,22.2,63.6,44.7c3.4,5.4,11.2,5.4,14.6,0c14-22.4,36.8-38.9,63.6-44.7 c30.1-6.5,52.1-34.5,48.8-67C-122.1,223-144.2,199.9-172.2,195.9z"/> </svg>  }
export function ImgHeart0({className, style}) { return <svg className={className+" SVG_heart0"} style={style} viewBox="-471 275 16 12" > <path d="M-463,287c-0.5,0-0.9-0.2-1.2-0.7c-0.7-1.2-1.9-2-3.2-2.3c-2.3-0.5-3.8-2.6-3.6-5c0.2-2.1,1.8-3.8,3.9-4.1 c1.6-0.2,3.1,0.4,4.1,1.5c1-1.1,2.5-1.7,4.1-1.5c2,0.3,3.7,2,3.9,4.1c0.2,2.3-1.3,4.5-3.6,5c-1.3,0.3-2.5,1.1-3.2,2.3 C-462.1,286.8-462.5,287-463,287z M-466.4,276.8c-0.1,0-0.3,0-0.4,0c-1.3,0.2-2.2,1.2-2.4,2.5c-0.1,1.4,0.8,2.7,2.2,3 c1.6,0.3,3,1.3,4,2.6c1-1.3,2.4-2.2,4-2.6c1.4-0.3,2.3-1.6,2.2-3c-0.1-1.3-1.1-2.3-2.4-2.5l0,0c-1.3-0.2-2.5,0.5-3,1.7 c-0.1,0.3-0.5,0.5-0.8,0.5c-0.4,0-0.7-0.2-0.8-0.5C-464.3,277.4-465.3,276.8-466.4,276.8z"/> </svg>  }
export function ImgHeart1({className, style}) { return <svg className={className+" SVG_heart1"} style={style} viewBox="-471 275 16 12" > <path d="M-459.1,276c-1.7-0.2-3.3,0.7-3.9,2.2c-0.6-1.5-2.2-2.4-3.9-2.2c-1.6,0.2-2.9,1.6-3.1,3.2 c-0.2,1.9,1.1,3.5,2.8,3.9c1.6,0.3,2.9,1.3,3.7,2.6c0.2,0.3,0.7,0.3,0.8,0c0.8-1.3,2.1-2.3,3.7-2.6c1.8-0.4,3-2,2.8-3.9 C-456.2,277.6-457.5,276.3-459.1,276z"/> </svg>  }
export function ImgHome({className, style}) { return <svg className={className+" SVG_home"} style={style} viewBox="0 0 50 50" > <path d="M49.3,24.9l-21-23.4c-1.8-2-5-2-6.8,0l-21,23.4c-1.5,1.6-0.3,4.2,1.9,4.2H5V50h15v-5.3 c0-3,2.4-5.3,5.3-5.3h0c3,0,5.3,2.4,5.3,5.3V50h15V29.1h1.8C49.7,29.1,50.8,26.6,49.3,24.9z"/> </svg>  }
export function ImgInsta({className, style}) { return <svg className={className+" SVG_insta"} style={style} viewBox="-519 261 240 240" > <path d="M-360,271h-78c-39.2,0-71,31.8-71,71v78c0,39.2,31.8,71,71,71h78c39.2,0,71-31.8,71-71v-78 C-289,302.8-320.8,271-360,271z M-399,454.5c-36.5,0-66.1-29.7-66.1-66.1s29.7-66.1,66.1-66.1s66.1,29.7,66.1,66.1 S-362.5,454.5-399,454.5z M-333,329.8c-8.2,0-14.8-6.6-14.8-14.8c0-8.2,6.6-14.8,14.8-14.8c8.2,0,14.8,6.6,14.8,14.8 C-318.1,323.1-324.8,329.8-333,329.8z"/> <path d="M-399,342.2c-25.4,0-46.1,20.7-46.1,46.1c0,25.4,20.7,46.1,46.1,46.1s46.1-20.7,46.1-46.1 C-352.9,362.9-373.6,342.2-399,342.2z"/> </svg>  }
export function ImgInstagram({className, style}) { return <svg className={className+" SVG_instagram"} style={style} viewBox="0 0 160 160" > <path d="M152.6,84.9c-0.1,12.3-0.2,21.9-0.4,31.6c-0.1,5.8-0.3,11.6-1.5,17.3c-1.8,8.2-5.8,14.9-12.8,19.7 c-4.3,2.9-9.2,4.5-14.4,5.2c-7.8,1.1-15.6,1.2-23.5,1.2c-14.8,0.1-29.5,0.1-44.3-0.1c-5.6,0-11.1-0.3-16.7-0.8 c-6.2-0.5-12.1-2.2-17.3-5.8c-6.8-4.7-10.6-11.4-12.4-19.4c-0.9-4.1-1.2-8.3-1.4-12.6c-0.4-21-0.3-42.1,0.1-63.1 c0.1-5.6,0.3-11.2,1.5-16.8C11.2,33.4,15,26.8,21.7,22c4.2-3,8.9-4.5,13.9-5.4s10-1.1,15.1-1.2c17.7-0.1,35.3-0.2,52.9-0.1 c5.8,0,11.7,0.3,17.5,0.8c6.5,0.6,12.6,2.3,18,6.3c6.4,4.7,10.1,11.2,11.7,18.9c0.9,4.2,1.3,8.5,1.4,12.7 C152.3,65.3,152.5,76.4,152.6,84.9z M80.1,132.8c25,0,45.2-20.2,45.3-45.2c0.1-24.9-20.3-45.3-45.3-45.3S34.9,62.5,34.8,87.5 C34.8,112.5,55.1,132.8,80.1,132.8z M127.1,51.1c5.8,0,10.5-4.7,10.6-10.4c0-5.9-4.6-10.6-10.4-10.7c-5.9,0-10.6,4.6-10.7,10.5 S121.2,51.1,127.1,51.1z"/> <path d="M80.1,117c-16.3,0-29.5-13.2-29.4-29.5c0-16.3,13.3-29.4,29.5-29.4c16.3,0,29.4,13.2,29.4,29.5 S96.4,117,80.1,117z"/> </svg>  }
export function ImgKey({className, style}) { return <svg className={className+" SVG_key"} style={style} viewBox="30 -30 240 240" > <path d="M269.7,126.3C266.2,86.2,232.6,56,193.1,56c-2.3,0-4.5,0.1-6.8,0.3c-16.1,1.4-30.6,7.7-42.2,17.2l-30.2-24.7 l20.9-25.6l-23.2-19L90.7,29.8L73.1,15.4l20.9-25.6l-23.2-19l-40,48.8l94.2,77.1c-6.8,12.7-10.1,27.5-8.8,43 c3.5,40.1,37.1,70.3,76.6,70.3c2.3,0,4.5-0.1,6.8-0.3C242.1,206,273.4,168.6,269.7,126.3z M229,163.2c-8.1,9.6-19.4,15.5-31.9,16.6 c-1.4,0.1-2.8,0.2-4.2,0.2c-11.7,0-22.9-4.4-31.6-12.3c-8.7-8-14.1-18.8-15.1-30.6c-1.1-12.5,2.7-24.7,10.8-34.3 c8.1-9.6,19.4-15.5,31.9-16.6c1.4-0.1,2.8-0.2,4.2-0.2c11.7,0,22.9,4.4,31.6,12.3c8.7,8,14.1,18.8,15.1,30.6 C240.9,141.4,237.1,153.6,229,163.2z"/> </svg>  }
export function ImgLeft({className, style}) { return <svg className={className+" SVG_left"} style={style} viewBox="0 0 16 16" > <polygon points="10.2,13.8 4.4,8 10.2,2.2 11.6,3.6 7.2,8 11.6,12.4 	"/> </svg>  }
export function ImgLilDropdown({className, style}) { return <svg className={className+" SVG_lilDropdown"} style={style} viewBox="3 5.5 10 7" > <polygon points="8,12.2 12.9,7.3 11.5,5.8 8,9.3 4.5,5.8 3.1,7.3 "/> </svg>  }
export function ImgLilDropup({className, style}) { return <svg className={className+" SVG_lilDropup"} style={style} viewBox="3 5.5 10 7" > <polygon points="8,5.8 3.1,10.8 4.5,12.2 8,8.7 11.5,12.2 12.9,10.8 "/> </svg>  }
export function ImgLink({className, style}) { return <svg className={className+" SVG_link"} style={style} viewBox="0 0 160 160" > <path d="M160,80c0-43.7-35.3-79.3-78.8-80c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0C35.3,0.7,0,36.3,0,80s35.3,79.3,78.8,80 c0.4,0,0.8,0,1.2,0s0.8,0,1.2,0C124.7,159.3,160,123.7,160,80z M136.1,58.7c-3.7,4.5-9.8,8.9-18,12.3c-0.8-15.6-3.6-30.8-8.3-43.1 C121.7,34.8,131.1,45.7,136.1,58.7z M80,140c-4.5,0-14.5-15-17.5-43.1C68.2,97.6,74,98,80,98c6,0,11.8-0.4,17.5-1.2 C94.5,125,84.5,140,80,140z M80,78c-6.6,0-12.7-0.5-18.3-1.5C62.4,39.8,74.8,20,80,20c5.2,0,17.6,19.8,18.3,56.5 C92.6,77.5,86.5,78,80,78z M50.2,27.9c-4.8,12.3-7.6,27.5-8.3,43c-8.3-3.5-14.4-7.9-18-12.3C28.9,45.7,38.3,34.8,50.2,27.9z M20,81.8c6.3,4.3,13.7,7.8,22,10.5c1,14.4,3.7,28.3,8.1,39.7C32.7,122,20.7,103.3,20,81.8z M109.8,132.1 c4.4-11.4,7.2-25.3,8.1-39.8c8.3-2.7,15.8-6.2,22-10.5C139.3,103.3,127.3,122,109.8,132.1z"/> </svg>  }
export function ImgMenu({className, style}) { return <svg className={className+" SVG_menu"} style={style} viewBox="0 0 16 16" > <path d="M14.5,5h-13C0.7,5,0,4.3,0,3.5v0C0,2.7,0.7,2,1.5,2h13C15.3,2,16,2.7,16,3.5v0 C16,4.3,15.3,5,14.5,5z"/> <path d="M14.5,15h-13C0.7,15,0,14.3,0,13.5v0C0,12.7,0.7,12,1.5,12h13c0.8,0,1.5,0.7,1.5,1.5v0 C16,14.3,15.3,15,14.5,15z"/> <path d="M14.5,10h-13C0.7,10,0,9.3,0,8.5v0C0,7.7,0.7,7,1.5,7h13C15.3,7,16,7.7,16,8.5v0 C16,9.3,15.3,10,14.5,10z"/> </svg>  }
export function ImgPen({className, style}) { return <svg className={className+" SVG_pen"} style={style} viewBox="-359 161 240 240" > <path d="M-590,322"/> <path d="M-190.3,401h-97.3c-6.3,0-11.3-5.1-11.3-11.3v-7.3c0-6.3,5.1-11.3,11.3-11.3h97.3c6.3,0,11.3,5.1,11.3,11.3v7.3 C-179,395.9-184.1,401-190.3,401z"/> <path d="M-155.7,255.7c-3.5-4.9-45.4-37.6-58.6-82.4c-1.7-5.7-5.5-12.3-11.2-12.3c-3,0-5.5,2.5-5.5,5.5v97.8 c6.8,3.3,11.3,10.5,10.4,18.8c-0.9,8.5-7.8,15.4-16.3,16.4c-11.2,1.2-20.7-7.5-20.7-18.4c0-7.4,4.3-13.7,10.6-16.7v-97.8 c0-3-2.5-5.5-5.5-5.5c-5.7,0-9.5,6.6-11.2,12.3c-13.2,44.8-55,77.5-58.6,82.4c-3.5,4.9-2.7,11.2,2,15.4c21.9,19.7,32,58.4,33.4,83.9 h95.8c1.4-25.5,11.5-64.2,33.4-83.9C-153,266.9-152.2,260.7-155.7,255.7z"/> </svg>  }
export function ImgPencil({className, style}) { return <svg className={className+" SVG_pencil"} style={style} viewBox="-359 161 240 240" > <path d="M-141.2,197.8l-14.6-14.6c-15.6-15.6-41-15.6-56.6,0l-5.7,5.7l71.2,71.2l5.7-5.7C-125.6,238.8-125.6,213.4-141.2,197.8z M-147.8,236.5l-46.6-46.6c8.9-4.3,19.9-2.7,27.3,4.7l14.6,14.6C-145.2,216.5-143.6,227.5-147.8,236.5z"/> <path d="M-343.3,314.1l-5.1,62c-0.3,4,1,7.8,3.8,10.6c2.7,2.7,6.6,4.1,10.6,3.8l62-5.1l113.8-113.8l-71.2-71.2L-343.3,314.1z M-283.1,370.1l-29.9,2.5l-17.6-17.6l2.5-29.9v0c6.2-6.2,16.3-6.2,22.5,0c3.7,3.7,5.2,8.7,4.5,13.5c-0.2,1.3,0.2,2.5,1.1,3.5 s2.2,1.3,3.5,1.1c4.8-0.7,9.8,0.8,13.5,4.5C-276.9,353.8-276.9,363.9-283.1,370.1z M-223,227.8c3.1,3.1,3.1,8.2,0,11.3l-69.7,69.7 c-3.1,3.1-8.2,3.1-11.3,0c-3.1-3.1-3.1-8.2,0-11.3l69.7-69.7C-231.2,224.6-226.1,224.6-223,227.8z"/> </svg>  }
export function ImgPointdown({className, style}) { return <svg className={className+" SVG_pointdown"} style={style} viewBox="-81 83 16 16" > <polygon points="-73,94.5 -77.9,89.6 -76.5,88.2 -73,91.7 -69.5,88.2 -68.1,89.6 		"/> </svg>  }
export function ImgPoll({className, style}) { return <svg className={className+" SVG_poll"} style={style} viewBox="-359 161 240 240" > <path d="M-590,322"/> <path d="M-185.2,194.1h-107.6c-17.8,0-32.3,14.5-32.3,32.3V334c0,17.8,14.5,32.3,32.3,32.3h97.9l42,22.6V226.4 C-152.9,208.6-167.4,194.1-185.2,194.1z M-252.9,325.7l-43.9-45.5l11.7-14.5l31.2,18.5l58.7-49.5l14.9,16L-252.9,325.7z"/> </svg>  }
export function ImgPost({className, style}) { return <svg className={className+" SVG_post"} style={style} viewBox="0 0 240 240" > <path d="M117.9,232C57.1,230.9,8,181.1,8,120c0-29.4,11.4-56.1,29.9-76.1c5.7-6.1,15.8-1.3,14.7,7 c-0.4,3-0.6,6-0.6,9.1c0,37.5,30.5,68,68,68c28.9,0,52.3,23.6,52,52.6C171.7,209.5,146.9,232.5,117.9,232z M120,161 c-10.5,0-19,8.5-19,19s8.5,19,19,19s19-8.5,19-19S130.5,161,120,161z"/> <path d="M202.1,196.1c-5.7,6.1-15.8,1.3-14.7-7c0.4-3,0.6-6,0.6-9.1c0-37.5-30.5-68-68-68c-28.9,0-52.3-23.6-52-52.6 c0.3-29,25.1-51.9,54.1-51.4C182.9,9.1,232,58.9,232,120C232,149.4,220.6,176.1,202.1,196.1z M120,41c-10.5,0-19,8.5-19,19 s8.5,19,19,19s19-8.5,19-19S130.5,41,120,41z"/> </svg>  }
export function ImgPound({className, style}) { return <svg className={className+" SVG_pound"} style={style} viewBox="16 -17 50 50" > <path d="M26.1,20.3c1.3,0,2.4-0.6,3.1-1.5c0.7-1,1.1-2.3,1.1-3.9v-4.4H26v-8h4.3v-2.7c0-5.4,1.1-9.6,3.2-12.4 c2.1-2.8,5.8-4.2,11.1-4.2c3.5,0,7.3,0.8,11.4,2.5l-1.1,9C52.4-6.4,50.2-7,48.5-7c-1.3,0-2.3,0.3-3,1c-0.7,0.6-1.1,1.6-1.1,2.8v5.6 h8.2v8h-8.2v8.5h11.1v9.6H26.1V20.3z"/> </svg>  }
export function ImgQuestion({className, style}) { return <svg className={className+" SVG_question"} style={style} viewBox="-84 86 12 12" > <path d="M-77,94.2h-2c0-1.7,1.1-2.3,1.8-2.7c0.7-0.4,1-0.6,1-1.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8h-2 c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8c0,2.3-1.3,3-2.1,3.4C-76.9,93.6-77,93.7-77,94.2z"/> <circle cx="-78" cy="96.5" r="1.5"/> </svg>  }
export function ImgRight({className, style}) { return <svg className={className+" SVG_right"} style={style} viewBox="0 0 16 16" > <polygon points="5.8,2.2 11.6,8 5.8,13.8 4.4,12.4 8.8,8 4.4,3.6 	"/> </svg>  }
export function ImgSearch({className, style}) { return <svg className={className+" SVG_search"} style={style} viewBox="-80 82 18 18" > <path d="M-73.6,94.8c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4S-70.1,94.8-73.6,94.8z M-73.6,84 c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S-71.2,84-73.6,84z"/> <rect x="-70.8" y="93.5" transform="matrix(0.6993 0.7148 -0.7148 0.6993 47.2384 76.6987)" width="6.4" height="2"/> </svg>  }
export function ImgSilver({className, style}) { return <svg className={className+" SVG_silver"} style={style} viewBox="0 0 100 100" > <circle cx="50" cy="50" r="50"/> <polygon points="50,8.1 62.9,31.4 89.1,36.5 70.9,56 74.2,82.4 50,71.2 25.9,82.4 29.2,56 11,36.5 37.1,31.4 "/> </svg>  }
export function ImgSmallArrowLeft({className, style}) { return <svg className={className+" SVG_smallArrowLeft"} style={style} viewBox="-7 9 16 16" > <polygon points="-1.3,17 3.3,21.7 3.3,12.3 "/> </svg>  }
export function ImgSmallArrowRight({className, style}) { return <svg className={className+" SVG_smallArrowRight"} style={style} viewBox="-7 9 16 16" > <polygon points="3.3,17 -1.3,12.3 -1.3,21.7 "/> </svg>  }
export function ImgStar({className, style}) { return <svg className={className+" SVG_star"} style={style} viewBox="0 0 50 50" > <path d="M26.8,1.2l6.8,12L47.1,16c1.5,0.3,2.1,2.2,1.1,3.3l-9.3,10.2l1.5,13.7c0.2,1.6-1.4,2.7-2.8,2.1L25,39.6 l-12.6,5.7c-1.4,0.6-3-0.5-2.8-2.1l1.5-13.7L1.8,19.3c-1.1-1.2-0.4-3,1.1-3.3l13.5-2.8l6.8-12C24-0.2,26-0.2,26.8,1.2z"/> </svg>  }
export function ImgSunglasses({className, style}) { return <svg className={className+" SVG_sunglasses"} style={style} viewBox="-359 161 240 240" > <path d="M-949,483"/> <path d="M-127.5,243.8c-8.2-2.2-22.3-7.3-53-7.3c-24,0-36.1,10.7-58.5,10.7s-34.5-10.7-58.5-10.7 c-30.6,0-44.8,5.1-53,7.3c-8.2,2.2-8.5,6.1-8.5,9.6s2.9,6.8,7.5,10c4.6,3.2,5.8,9.3,5.8,17.5c0,24.5,21.6,44.4,48.2,44.4 c27.3,0,43.1-10.6,47.1-32.4c1.1-5.5,2.3-19.1,3.2-23.4c0.9-4.6,1.2-8.5,8.2-8.5s7.2,3.9,8.2,8.5c0.9,4.4,2.1,17.7,3.2,23.4 c4,21.7,19.8,32.4,47.1,32.4c26.6,0,48.2-19.9,48.2-44.4c0-8.2,1.2-14.3,5.8-17.5c4.6-3.2,7.5-6.5,7.5-10S-119.4,246-127.5,243.8z M-297.8,268.5c-8.5,0-15.8,5.7-15.8,12.4c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-15.7,14.2-28.4,31.8-28.4c4.4,0,8,3.6,8,8 S-293.3,268.5-297.8,268.5z M-180.3,268.5c-8.5,0-15.8,5.7-15.8,12.4c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-15.7,14.2-28.4,31.8-28.4 c4.4,0,8,3.6,8,8S-175.8,268.5-180.3,268.5z"/> </svg>  }
export function ImgTick({className, style}) { return <svg className={className+" SVG_tick"} style={style} viewBox="-84 86 12 12" > <polygon points="-79.8,96.6 -84,92.2 -82.6,90.8 -79.7,93.7 -73.4,87.4 -72,88.8 	"/> </svg>  }
export function ImgTrophy({className, style}) { return <svg className={className+" SVG_trophy"} style={style} viewBox="30 -30 240 240" > <path d="M150,127.1L150,127.1c-44.2,0-70-35.8-70-80v-59.4C80-22.1,87.9-30,97.7-30h104.7 c9.8,0,17.7,7.9,17.7,17.7v59.4C220,91.2,194.2,127.1,150,127.1z"/> <path d="M170,154.5V99.7h-40v54.8c0,14.1-11.4,25.5-25.5,25.5H85c-8.3,0-15,6.7-15,15l0,0c0,8.3,6.7,15,15,15h130 c8.3,0,15-6.7,15-15l0,0c0-8.3-6.7-15-15-15h-19.5C181.4,180,170,168.6,170,154.5z"/> <path d="M80,12.6c16.5,0,30,11.5,30,25.7S96.5,64,80,64S50,52.5,50,38.3S63.5,12.6,80,12.6 M80-7.4 c-27.6,0-50,20.5-50,45.7S52.4,84,80,84s50-20.5,50-45.7S107.6-7.4,80-7.4L80-7.4z"/> <path d="M220.1,12.6c16.5,0,30,11.5,30,25.7S236.6,64,220.1,64s-30-11.5-30-25.7S203.6,12.6,220.1,12.6 M220.1-7.4 c-27.6,0-50,20.5-50,45.7s22.4,45.7,50,45.7s50-20.5,50-45.7S247.7-7.4,220.1-7.4L220.1-7.4z"/> </svg>  }
export function ImgTutorials({className, style}) { return <svg className={className+" SVG_tutorials"} style={style} viewBox="-272 395.9 240 240" > <path d="M-222,548.3c17,1.9,36,6,50,11.6V547c-12.1-5.6-31.3-10.6-50-12.9V548.3z"/> <path d="M-222,483.7c17.8,2.2,36.5,6.9,50,12.6v-12.9c-12.4-6.1-31.9-11.5-50-13.9V483.7z"/> <path d="M-222,516c16.6,2,35.8,6.2,50,12.1v-12.9c-12.3-5.8-31.7-11.1-50-13.4V516z"/> <path d="M-82,548.3c-17,1.9-36,6-50,11.6V547c12.1-5.6,31.3-10.6,50-12.9V548.3z"/> <path d="M-82,483.7c-17.8,2.2-36.5,6.9-50,12.6v-12.9c12.4-6.1,31.9-11.5,50-13.9V483.7z"/> <path d="M-82,516c-16.6,2-35.8,6.2-50,12.1v-12.9c12.3-5.8,31.7-11.1,50-13.4V516z"/> <path d="M-52,436.5L-52,436.5c0-5.9-4.9-10.5-10.8-10.2c-46.9,2.6-75.5,15.5-89.2,23.8c-13.8-8.3-42.3-21.2-89.2-23.8 c-5.8-0.3-10.8,4.3-10.8,10.2l0,0c-2-0.1-4-0.2-6.1-0.3c-7.6-0.3-13.9,5.7-13.9,13.3v141c0,8,7,14.2,15,13.2 c26-3.3,78.4-7.4,95,10.3v1.9c0,0,0.6,0,1.6,0c0.4,0,0.8,0,1.2,0c4,0,7.2,0,7.2,0c2.6,0,5.3,0,7.2,0c0.4,0,0.9,0,1.2,0 c1,0,1.6,0,1.6,0V614c16.6-17.7,69-13.6,95-10.3c8,1,15-5.2,15-13.2v-141c0-7.6-6.3-13.6-13.9-13.3C-48,436.2-50,436.3-52,436.5z M-162,586.5c-11.4-6.6-26.6-11.4-45.8-14.7c-8.7-1.5-17.1-2.4-24.2-3V447c24.8,2.4,42.7,7.8,54.1,12.5c7,2.8,12.2,5.6,15.9,7.9 V586.5z M-72,568.8c-7.1,0.6-15.5,1.5-24.2,3c-19.3,3.2-34.5,8.1-45.8,14.7V467.4c3.7-2.2,8.9-5,15.9-7.9 c11.4-4.6,29.3-10.1,54.1-12.5V568.8z"/> </svg>  }
export function ImgTwitter({className, style}) { return <svg className={className+" SVG_twitter"} style={style} viewBox="0 0 160 160" > <path d="M0,146.5c0.6,0,0.9-0.1,1.2-0.1c3,0,6,0.1,9,0c3.5-0.2,7-0.5,10.4-1c5.8-0.8,11.4-2.2,16.9-4.1 c5.1-1.8,10.1-4,14.5-7.2c0.2-0.2,0.5-0.4,0.9-0.7C5.3,125.7,0,79,9.4,42.6c0.4-0.9,0.9-1.8,1.4-2.9c1.1,1.2,2,2.3,3,3.4 c2.3,2.5,4.6,5,7,7.4c6.1,6.3,12.9,11.7,20.3,16.4c6.6,4.2,13.5,7.7,21,10c4.5,1.4,9,2.4,13.8,2.6c1,0,1.9,0.1,2.9,0.2 c0.8,0.1,1.1-0.3,1.1-1.1c0-4.5-0.1-9.1,0-13.6c0.1-4.7,1.2-9.2,3.1-13.5c1.6-3.6,3.7-6.8,6.4-9.6c4.5-4.5,9.9-7.5,16.2-8.5 c6.1-0.9,12.1-0.4,17.9,1.6c3.5,1.2,6.7,3,9.4,5.5c0.4,0.4,0.8,0.8,1.3,1.1c0.4,0.2,0.9,0.4,1.2,0.3c7.5,0.4,17.9-1.8,24.3-5.7 c0.1,0.1,0.1,0.1,0.2,0.2c-1.5,12.1-6.7,20.8-13.3,27.6c0,1.9,0.2,3.9,0.1,5.8c0,2.4-0.2,4.9-0.4,7.3c-0.2,2.4-0.4,4.8-0.8,7.1 c-0.9,4.4-1.7,8.8-3.1,13.1c-1.4,4.5-3.2,8.8-5.2,13c-2.6,5.6-6,10.8-9.9,15.7c-3,3.8-6.4,7.3-9.9,10.6c-4.2,3.9-8.8,7.2-13.7,10.1 c-6.1,3.7-12.6,6.5-19.3,8.7c-5.1,1.6-10.4,2.8-15.7,3.5c-4.4,0.6-8.8,1-13.3,1c-2.9,0-5.8,0.2-8.7-0.1c-4.9-0.4-9.8-1-14.7-1.7 c-5.5-0.8-10.9-2.2-16.1-4s-10.2-4-14.9-6.8C0.7,147.1,0.5,146.9,0,146.5z"/> </svg>  }
export function ImgUser({className, style}) { return <svg className={className+" SVG_user"} style={style} viewBox="-359 161 240 240" > <path d="M-590,322"/> <path d="M-239,389.5c29.4,0,56.4-10.3,77.6-27.4c7.4-6,9.1-16.6,4-24.6c-17.2-26.9-47.3-44.8-81.7-44.8 s-64.5,17.9-81.7,44.8c-5.1,8-3.3,18.6,4,24.6C-295.4,379.2-268.4,389.5-239,389.5z"/> <circle cx="-239" cy="227.4" r="50.9"/> </svg>  }
export function ImgYoutube({className, style}) { return <svg className={className+" SVG_youtube"} style={style} viewBox="0 0 160 160" > <path d="M88.6,160c-5.4,0-10.8,0-16.2,0c-0.2,0-0.4-0.1-0.6-0.1c-5-0.1-10.1-0.2-15.1-0.3c-6-0.2-12.1-0.4-18.1-0.7 c-3.9-0.2-7.9-0.5-11.8-0.9c-2.8-0.3-5.7-0.6-8.5-1.3c-7.7-1.8-12.8-6.7-15-14.3c-0.8-2.6-1.2-5.3-1.5-7.9 c-0.5-4.1-0.9-8.3-1.2-12.4c-0.3-4.4-0.5-8.9-0.5-13.4C0,104.4,0,100,0.1,95.6c0.2-4.9,0.5-9.9,0.9-14.8c0.3-4.7,0.9-9.3,1.9-13.9 c1.2-6.2,4.5-10.9,9.8-14.2c2.7-1.7,5.8-2.5,8.9-2.8c4.3-0.5,8.6-1,13-1.2c5.1-0.3,10.3-0.6,15.4-0.7c7-0.2,14.1-0.3,21.1-0.5 c0.4,0,0.8-0.1,1.1-0.1c5.4,0,10.8,0,16.2,0c0.2,0,0.4,0.1,0.6,0.1c5.7,0.1,11.5,0.2,17.2,0.4c6.8,0.2,13.6,0.6,20.4,0.9 c4.8,0.3,9.5,0.7,14.2,1.5c2.4,0.4,4.8,1.1,6.9,2.3c5.5,3.1,8.9,7.8,10.2,14c0.6,2.9,1.1,5.9,1.5,8.9c0.3,2,0.5,4,0.5,6 c0,13.1,0,26.3,0,39.4c0,3.1,0,6.1-0.4,9.2c-0.5,4-0.9,8.1-1.9,12c-1.4,5.4-4.5,9.6-9.2,12.5c-2.8,1.8-6,2.6-9.3,2.9 c-4.3,0.5-8.6,0.9-12.9,1.2c-5.2,0.4-10.4,0.6-15.6,0.7c-6.9,0.2-13.7,0.3-20.6,0.5C89.6,160,89.1,160,88.6,160z M64.4,127.9 c14-8.1,27.9-16.1,42-24.2c-14-8.1-28-16.1-42-24.2C64.4,95.7,64.4,111.7,64.4,127.9z"/> </svg>  }
export function ImgZoom({className, style}) { return <svg className={className+" SVG_zoom"} style={style} viewBox="-471 273 16 16" > <path d="M-465,285c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S-461.7,285-465,285z M-465,275c-2.2,0-4,1.8-4,4s1.8,4,4,4 s4-1.8,4-4S-462.8,275-465,275z"/> <rect x="-462.3" y="284.6" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -984.5169 163.3409)" width="7.7" height="2"/> <rect x="-468" y="278" width="6" height="2"/> <rect x="-466" y="276" width="2" height="6"/> </svg>  }