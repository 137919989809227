import React, {useContext, useEffect, useRef, useState} from 'react';
import { AccountContext, ModalContext, useResponsive, useSubmit } from '../../hooks';
import Button from '../controls/Button';
import Radiobox from '../controls/Radiobox';
import SliderOptions from '../controls/SliderOptions';
import ModalHeading from '../modal/ModalHeading';
import axios from 'axios';
import ModalMessage from '../modal/ModalMessage';
import Input from '../controls/Input';
import Btn from '../controls/Btn';
import { ImgEyedrop } from '../../pictures/SVG';
import Slider from '../controls/Slider';


export default function AnimationSettings({ close, post }) {
    let [c, submit, onSubmit] = useSubmit();
    let [width, setWidth] = useState('1080');
    let [height, setHeight] = useState('1080');
    let [framerate, setFramerate] = useState('30 fps');
    let [motionBlur, setMotionBlur] = useState('Good quality');
    let [secondsGap, setSecondsGap] = useState('3 seconds');
    let [loops, setLoops] = useState('1');
    let [colour, setColour] = useState();
    let [scale, setScale] = useState(1);

    let imgRef = useRef();
    let canvasRef = useRef();

    let [customColour, setCustomColour] = useState('#000000');

    let [eyedrop, setEyedrop] = useState(false);

    let [halfWidth, setHalfWidth] = useState(0);
    let [previewWidth, setPreviewWidth] = useState(0);
    let [previewHeight, setPreviewHeight] = useState(0);
    let [previewScale, setPreviewScale] = useState(0);

    let { innerWidth } = useResponsive();

    let [widthError, setWidthError] = useState('');
    let [heightError, setHeightError] = useState('');

    let Modals = useContext(ModalContext);

    let { user } = useContext(AccountContext);

    let [loading, setLoading] = useState(false);
    let [wiggle, setWiggle] = useState(0);
    let [tooltip, setTooltip] = useState(false);

    function wigglewiggle() {
        if (wiggle==0) {
            let direction = Math.random() < 0.5 ? -1 : 1;
            setWiggle(1 * direction);
            setTimeout(() => {
                direction *= -1;
                setWiggle(2 * direction);
            }, 120)
            setTimeout(() => {setWiggle(0)}, 240)
        }
    }


    onSubmit(async () => {
        if (!widthError && !heightError) {
            setLoading(true);
            let { data } = await axios.post('/api/render-animation', {
                width: parseInt(width),
                height: parseInt(height),
                framerate: parseInt(framerate),
                loops: parseInt(loops),
                colour, scale,
                motionBlur: { 'None': 1, 'Basic quality': 4, 'Good quality': 8, 'Best quality': 12 }[motionBlur],
                secondsGap: parseInt(secondsGap),
                user, post: { _id: post._id }
            });
            await close();
            if (data.success) {
                Modals.create(ModalMessage, { title: "Download animation", body: "Your animation is being rendered! \nYou will be emailed a download link once the animation has been fully rendered - This might take a few minutes. \nThe download link will expire after 24 hours." })
            } else {
                Modals.create(ModalMessage, { title: "Error", body: "I'm sorry, there was an error!" })
            }
        }
    })

    let ref = useRef();

    useEffect(() => {
        let w = ref.current.getBoundingClientRect().width;
        setHalfWidth(w / 2 - 8);
    }, [innerWidth]);

    useEffect(() => {
        let previewWidth = halfWidth;
        let w = parseInt(width);
        let h = parseInt(height);
        let r = h / w;
        let previewHeight = r * halfWidth;

        if (previewHeight > 333) {
            previewHeight = 333;
            previewWidth = 333/r;
        }

        let scaleWidth = previewWidth / post.picture.width;
        let scaleHeight = previewHeight / post.picture.height;
        let previewScale = Math.min(scaleWidth, scaleHeight);

        setPreviewHeight(previewHeight);
        setPreviewWidth(previewWidth);
        setPreviewScale(previewScale);
    }, [halfWidth, width, height])


    useEffect(() => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        canvas.width = post.picture.width;
        canvas.height = post.picture.height;

        let img = new Image();
        img.src = '/api/pictures/' + post.picture.path;
        img.onload = () => {
            ctx.drawImage(img, 0, 0, post.picture.width, post.picture.height);
            canvasRef.current = canvas;
            let colour = dropEye(0, 0);
            setCustomColour(colour);
            setColour(colour);
        };

        return () => {
            canvasRef.current = null;
            canvas.remove();
        }
    }, []);

    let timeout = useRef();
    useEffect(() => {
        if (eyedrop) {
            wigglewiggle();
            let click = () => setEyedrop(false);
            document.addEventListener('mousedown', click);
            timeout.current = setTimeout(() => {
                setTooltip(true)
            },360)
            return ()=>document.removeEventListener('mousedown', click);
        } else {
            clearTimeout(timeout.current);
            setTooltip(false);
        }
    },[eyedrop])

    function dropEye(x, y) {
        let canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');
        let pixel = ctx.getImageData(x, y, 1, 1).data;
        return `#${((1 << 24) | (pixel[0] << 16) | (pixel[1] << 8) | pixel[2]).toString(16).slice(1)}`;
    };
    

    return <div className="AnimationSettings modal">
        <ModalHeading title="Animation settings" close={close} />
        <div className="body">
            <div className="flexSplit" ref={ref}>
                <div className="flex1 animationSetting">
                    <form onSubmit={submit}>

                        <label>Dimensions</label>
                        <div>
                            <label>
                                Width
                                <Input
                                    type='number'
                                    value={width}
                                    setValue={setWidth}
                                    disabled={loading || eyedrop}
                                    error={widthError}
                                    setError={setWidthError}
                                    validation={n => {
                                        n = parseInt(n);
                                        if (n < 10) return "Too small!";
                                        if (n > 1080) return "Maximum size is 1080";
                                    }}
                                    attempts={c}
                                />
                            </label>
                            <label>
                                Height
                                <Input
                                    type='number'
                                    value={height}
                                    setValue={setHeight}
                                    disabled={loading || eyedrop}
                                    error={heightError}
                                    setError={setHeightError}
                                    validation={n => {
                                        n = parseInt(n);
                                        if (n < 10) return "Too small!";
                                        if (n > 1080) return "Maximum size is 1080";
                                    }}
                                    attempts={c}
                                />
                            </label>
                        </div>

                        <label>Scale</label>
                        <div>
                            <Slider
                                value={scale}
                                setValue={setScale}
                                min={0.25}
                                max={1.0}
                                display={false}
                                disabled={loading || eyedrop}
                                width={halfWidth}
                            />
                        </div>
                        <div className="divGap" />

                        <label>Background colour</label>
                        <div>
                            <Colours setColour={setColour} colour={colour} setEyedrop={setEyedrop} customColour={customColour} eyedrop={eyedrop}/>
                        </div>

                        <div className="divGap"/>
                        <label>Frame rate</label>
                        <div className="framerate">
                            <Radiobox option={framerate} setOption={setFramerate} name="frame rate" label="25 fps" disabled={loading || eyedrop}/>
                            <Radiobox option={framerate} setOption={setFramerate} name="frame rate" label="30 fps" disabled={loading || eyedrop}/>
                        </div>
                        <div className="divGap" />
                        
                        <label>Motion blur</label>
                        <div>
                            <SliderOptions
                                selected={motionBlur}
                                setSelected={setMotionBlur}
                                options={[
                                    'None',
                                    'Basic quality',
                                    'Good quality',
                                    'Best quality',
                                ]}
                                    disabled={loading || eyedrop}
                                    width={halfWidth}
                            />
                        </div>
                        <div className="divGap" />
                        
                        <label>Gap between rotations</label>
                        <div>
                            <SliderOptions
                                selected={secondsGap}
                                setSelected={setSecondsGap}
                                options={['1 second', '2 seconds', '3 seconds', '4 seconds', '5 seconds', '6 seconds', '7 seconds', '8 seconds', '9 seconds', '10 seconds', '11 seconds', '12 seconds']}
                                disabled={loading || eyedrop}
                                width={halfWidth}
                            />
                        </div>
                        <div className="divGap" />
                        
                        <label>Number of loops</label>
                        <div>
                            <SliderOptions
                                selected={loops}
                                setSelected={setLoops}
                                options={['1','2','3','4','5',]}
                                disabled={loading || eyedrop}
                                width={halfWidth}
                            />
                        </div>

                        <br/>

                        <div className="formButtons">
                            <div className="buttons">
                                <Button submit loading={loading} disabled={eyedrop}>Submit</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="flex1 animationPreview">
                    {colour?<div style={{width:previewWidth}}>
                        <div className="tooltipContainer">
                            <div className={tooltip ? "tooltip" : "hidden tooltip"}>
                                Pick your colour on the image!
                            </div>
                        </div>
                        <div className="previewBox centreFlex" style={{width:previewWidth, height:previewHeight, backgroundColor: colour}}>
                            <img ref={imgRef} className={eyedrop ? 'eyedropping' : ''} src={'/api/pictures/' + post.picture.path} style={{
                                width: post.picture.width * previewScale * scale,
                                height: post.picture.height * previewScale * scale,
                                transform: `rotate(${wiggle}deg)`
                            }} onMouseDown={e => {
                                if (eyedrop) {
                                    let img = imgRef.current;
                                    let rect = img.getBoundingClientRect();
                                    let x = Math.round((e.clientX - rect.left) / (previewScale * scale));
                                    let y = Math.round((e.clientY - rect.top) / (previewScale * scale));
                                    let colour = dropEye(x, y);
                                    setCustomColour(colour);
                                    setColour(colour);
                                }
                            }} alt="Preview..."/>
                        </div>
                        <div className="cantseeme">
                            Pick your colour on the image!
                        </div>
                    </div>:null}

                </div>
            </div>

        </div>
    </div>
}

function Colours({ setColour, customColour, setEyedrop, eyedrop }) {
    let [disabled, setDisabled] = useState(false);
    let [hidden, setHidden] = useState(false);

    useEffect(() => {
        if (eyedrop) {
            setDisabled(true);
        } else {
            setTimeout(() => {
                setDisabled(false);
            },100)
        }
    }, [eyedrop])

    useEffect(() => {
        console.log(customColour);
        setHidden(customColour == '#000000' || customColour == '#ffffff');
    }, customColour)

    useEffect(() => {
    console.log({hidden})        
    },[hidden])
    
    return <div className="colourPicker">
        <Btn disabled={disabled}><div className="swatch" onClick={(()=>setColour('#000000'))} style={{ backgroundColor: '#000000' }} /></Btn>
        <Btn disabled={disabled}><div className="swatch" onClick={(()=>setColour('#ffffff'))} style={{ backgroundColor: '#ffffff' }} /></Btn>
        <Btn disabled={disabled}><div className={`swatch ${hidden ? 'hidden' : ''}`} onClick={(()=>setColour(customColour))} style={{ backgroundColor: customColour }} /></Btn>
        <Btn disabled={disabled} onClick={()=>setEyedrop(true)}><ImgEyedrop/></Btn>
    </div>
}