import React, { useEffect, useRef, useState } from 'react';

export default function SliderOptions({ options, selected, setSelected, disabled, width=200 }) {
    let [clicked, setClicked] = useState(false);
    let [i, setI] = useState(options.indexOf(selected))
    let ref = useRef();
    useEffect(() => {
        if (clicked) {
            function mouseUp() {
                setClicked(false);
            }
            document.addEventListener('mouseup', mouseUp)
            return ()=>document.removeEventListener('mouseup', mouseUp)
        }
    }, [clicked])

    useEffect(() => {
        setClicked(false);
    },[disabled])
    
    function getI(e) {
        let x = e.clientX - ref.current.getBoundingClientRect().left;
        let positions = options.map((o, i) => (width / (options.length - 1)) * i);
        let distances = positions.map(p => Math.abs(p - x));
        let min = Math.min(...distances);
        let i = distances.indexOf(min);
        setI(i)
        setSelected(options[i])
    }
    return <div className={`Slider ${disabled?'disabled':''}`}>
        {options[i]}
        <div
            ref={ref}
            className="slider"
            onMouseDown={(e) => {
                if (!disabled) {
                    setClicked(true);
                    getI(e);
                }
            }}
            style={{ width }}
            onMouseMove={e => {
                if (clicked) {
                    getI(e);
                }
            }}
        >
            <div className="horizontalLine" />
            {options.map((o, i) => <div key={i} className="verticalLine" style={{
                left:(width/(options.length-1))*i
            }}/> )}
            <div className="knob" style={{
                left:(width / (options.length - 1)) * i - 3
            }}/>
        </div>
    </div>
}