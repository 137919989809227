import React, {useState, useRef, useEffect, useContext, memo} from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, NavLink } from "react-router-dom";
import {
  useModal, ModalContext, useTransition, useUsers, UsersContext,
  useAccount, AccountContext, ToastContext, useMount,
  useResponsive, ResponsiveContext, HistoryContext,
  VisibilityContext, usePageVisible, CentreKeyContext,
  SocketContext, useAsync, NotificationsContext, useGetState, useForceRender,
  ContestForceRenderContext, CentreForceRenderContext, useDark,
  useId, IdContext, DarkContext, CurrencyContext, useCurrency, useTimeout, ExpandLeftMenuContext,
  SoundsContext,
  useSound,
  useTitle
} from './hooks';
import E from './components/top level/E';
import Toast from './components/modal/Toast';
import Admin from './components/top level/Admin';
import Search from './components/top level/Search';
import Hearted from './components/top level/Hearted';
import Profile from './components/top level/Profile';
import Sketches from './components/top level/Sketches';
import LeftMenu from './components/top level/LeftMenu';
import Tutorial from './components/top level/Tutorial';
import Tutorials from './components/top level/Tutorials';
import PostsPanel from './components/top level/PostsPanel';
import HallOfFame from './components/top level/HallOfFame';
import Performance from './components/top level/Performance';
import SiteRules from './components/top level/info/SiteRules';
import ContestsPanel from './components/top level/ContestsPanel';
import Subscription from './components/top level/info/Subscription';
import LeaderboardPanel from './components/top level/LeaderboardPanel';
import SubmissionRules from './components/top level/info/SubmissionRules';
import ChallengesPanel from './components/top level/ChallengesPanel';
import AdminAnalytics from './components/top level/AdminAnalytics';
import VotingRules from './components/top level/info/VotingRules';
import AdvertsPanel from './components/top level/AdvertsPanel';
import GlyphsPanel from './components/top level/GlyphsPanel';
import Glossary from './components/top level/info/Glossary';
import PostSingle from './components/top level/PostSingle';
import Challenges from './components/top level/Challenges';
import Following from './components/top level/Following';
import MainFeed from './components/top level/MainFeed';
import Query from './components/top level/Admin/Query';
import Messages from './components/top level/Messages';
import Contest from './components/top level/Contest';
import Adverts from './components/top level/Adverts';
import Advert from './components/top level/Advert';
import Glyphs from './components/top level/Glyphs';
import Home from './components/top level/Home';
import Nav from './components/top level/Nav';

import io from "socket.io-client";
import axios from 'axios';
import ModalMessage from './components/modal/ModalMessage';
import Arranger from './components/controls/Arranger';
import Logo from './components/Logo/Logo';
import DuelArena from './components/top level/DuelArena';
import DuelsAdmin from './components/top level/Admin/DuelsAdmin';
import DuelsAdminPanel from './components/top level/Admin/DuelsAdminPanel';
import TextFeed from './components/top level/TextFeed';
import Duel from './components/top level/Duel';
import DuelsPanel from './components/top level/DuelsPanel';
import MessagesPanel from './components/top level/MessagesPanel';

if (process.env.NODE_ENV && process.env.NODE_ENV != 'development') {
  console._log = console.log;
  console.log = () => {}
}

function urlBase64ToUint8Array(base64String) {
  let padding = '='.repeat((4 - base64String.length % 4) % 4);
  let base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


let App2 = memo(props => {
  let [centreKey, setCentreKey] = useState();
  let { componentDidMount } = useMount();
  let { history } = props;
  let { route, arg, arg2, arg3, arg4 } = props.match.params;
  let forceCentre = useRef(false);
  let unForceCentre = useRef(false);
  let LeftPanel = useTransition("fader", 0.3,{wait:true});
  let CentrePanel = useTransition("fader", 0.3, {forceKey:forceCentre, unForceKey:unForceCentre, setKey:setCentreKey, wait:true, scrollUp:true});
  let RightPanel = useTransition("fader", 0.3,{wait:true});
  let { login, logout, verify, user, setUser } = useContext(AccountContext);
  let Toasts = useContext(ToastContext);
  let Modals = useContext(ModalContext);
  let [socket, setSocket] = useState();
  let [contestForce, contestForceRender] = useForceRender();
  let [centreForce, centreForceRender] = useForceRender();
  let [showRightPanel, setShowRightPanel] = useState(false);
  let [expandLeftMenu, setExpandLeftMenu] = useState(false);
  let { device, tablet } = useContext(ResponsiveContext);
  let { pageVisible, getPageVisible } = useContext(VisibilityContext);
  let [clicked, setClicked] = useState(false);
  let {playPop} = useContext(SoundsContext);

  useEffect(() => {
    if (socket && clicked) {
      socket.on('notification', (eventData) => {
        console.log('POP')
        playPop();
      });
    }
  },[clicked, socket])

  useEffect(() => {
    setExpandLeftMenu(false);
  },[route, centreKey])

  useEffect(() => {
    setExpandLeftMenu(false);
  },[device])

  let forceNavigate = url => {
    forceCentre.current = true;
    history.push(url);
    let URL = '/' + route + (arg ? '/' + arg : '') + (arg2 ? '/' + arg2 : '') + (arg3 ? '/' + arg3 : '') + (arg4 ? '/' + arg4 : '');
    if (url.toLowerCase() == URL.toLowerCase()) {
      centreForceRender();
    }
    setTimeout(() => {
      forceCentre.current = false;
    }, 50);
  }
  
  let navigate = url => {
    history.push(url);
  }

  let noNavigate = url => {
    unForceCentre.current = true;
    history.push(url);
    setTimeout(() => {
      unForceCentre.current = false;
    }, 50);
  }

  let [notifications, setNotifications, getNotifications] = useGetState([]);

  useAsync(async () => {
    let socket;
    
    if (user) {
      let { data } = await axios.get('/api/notifications/' + user.username.toLowerCase());
      let { notifications } = data ? data : [];
      setNotifications(notifications);

      let sub;

      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          let registration = await navigator.serviceWorker.getRegistration();
          if (!registration) registration = await navigator.serviceWorker.register('/sw.js');
          if (registration) {
            sub = await registration.pushManager.getSubscription();
            if (!sub) {
              let permission = await Notification.requestPermission();
              if (permission === 'granted') {
                let publicVapidKey = 'BHHlBRRpURlmByz_-YXW4F-SM7ImOWCgDoCvI52DPc_Yd9_7NbkYjuvKM4dcstczmk-zyzPDTjmu5dwJY189EPA';
                sub = await registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: urlBase64ToUint8Array(publicVapidKey), });               
                await axios.post('/api/push-subscription', { user, sub });
              }
            }
          }
        } catch (e) {
          console.log('Service worker error: ', e.message);
        }
      }

      let clientID = new Date().getTime() + Math.floor(Math.random() * 1000000000);

      socket = process.env.NODE_ENV !== "production" ? io('localhost:5000') : io({ path: "/socket.io" });

      socket.on('connect', () => {
        console.log('Socket.io', 'Connected to the server');
        socket.emit('username', { username: user.username, clientID, sub: sub ? btoa(`${user.username} ${JSON.stringify(sub.toJSON())}`) : null});
      });
      
      socket.on('disconnect', () => {
        console.log('Socket.io', 'Disconnected from the server');
      });
      
      socket.on('reconnect_attempt', () => {
        console.log('Socket.io', 'Attempting to reconnect...');
      });
      
      socket.on('reconnect', () => {
        console.log('Socket.io', 'Reconnected to the server');
        socket.emit('username', { username: user.username, clientID });
      });
      
      socket.on('reconnect_error', (error) => {
        console.error('Socket.io Reconnection error:', error);
      });

      socket.on('connect_error', (error) => {
        console.error('Socket.io Connection error:', error);
      });
      
      socket.on('error', (error) => {
        console.error('Socket.io error:', error);
      });

      socket.on('verify', () => {
        user.verified = true;
        setUser(user);
      });

      setSocket(socket);

      if (!user.verified) {
        let { data } = await axios.post('/api/check-verified', { user });
        if (data) {
          user.verified = true;
          setUser(user);
        }
      }

    } else {
      if (socket) socket.emit('logout');
      setSocket(null);
    }

    return () => {
      if (socket) {
        socket.disconnect();
        socket.off();
      }
    };
  }, [user])

  useEffect(() => {
    if (socket) {
      socket.emit(pageVisible ? 'pageVisible' : 'pageInvisible');
    }
  },[pageVisible, socket])
  
  function setCentrePanel(component, args, key) {
    let c = <div>{component}</div>
    CentrePanel.set(component, args, key);
  }
  function setLeftPanel(component, args, key) {
    LeftPanel.set(component, args, key);
  }
  function setRightPanel(component, args, key) {
    if (!component) key = 'NULL';
    RightPanel.set(component, args, key,()=>setShowRightPanel(!!component));
  }

  let { documentTitle, setDocumentTitle } = useTitle();

  useEffect(() => {
    (async () => {
      route = route ? route.toLowerCase() : '';

      if (!route) {
        setDocumentTitle('$');
      }

      if (route == 'posts' || (user && !route)) {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'MainFeed*' + page;
        setCentrePanel(MainFeed, { page, route }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('$');
      }
      else if (!arg && parseInt(route)+''==route) {
        let page = parseInt(route) - 1;
        let key = 'MainFeed*' + page;
        setCentrePanel(MainFeed, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('$');
      }
      else if (route == 'challenges') {
        if (arg == 'open') {
          let page = arg2 ? parseInt(arg2) - 1 : 0;
          let key = `Challenges*Open*${page}`;
          setCentrePanel(Challenges, { page, route:'Open' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(ChallengesPanel, {}, 'ChallengesPanel');
        } else if (arg == 'voting') {
          let page = arg2 ? parseInt(arg2) - 1 : 0;
          let key = `Challenges*Voting*${page}`;
          setCentrePanel(Challenges, { page, route:'Voting' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(ChallengesPanel, {}, 'ChallengesPanel');
        } else if (arg == 'concluded') {
          let page = arg2 ? parseInt(arg2) - 1 : 0;
          let key = `Challenges*Concluded*${page}`;
          setCentrePanel(Challenges, { page, route:'Concluded' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(ChallengesPanel, {}, 'ChallengesPanel');
        } else {
          let page = arg ? parseInt(arg) - 1 : 0;
          let key = `Challenges*${page}`;
          setCentrePanel(Challenges, { page, route:'All' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(ChallengesPanel, {}, 'ChallengesPanel');
        }
        setDocumentTitle('Challenges - $');
      }
      else if (route == 'text') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'Text*' + page;
        setCentrePanel(TextFeed, { page, route }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('Text posts - $');
      }


      else if (route == 'sketches') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'Sketches*' + page;
        setCentrePanel(Sketches, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, { feed: 'Sketches' }, 'SketchesPanel');
        setDocumentTitle('Sketches - $');
      }
      
      else if (route == 'user') {
        let username = arg ? arg : '';
        if (arg2 == 'sketches') {
          let page = arg3 ? parseInt(arg3) - 1 : 0;
          let key = `User$${username.toLowerCase()}*Sketches*${page}`;
          setCentrePanel(Profile, { username, page, feed:'Sketches' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        } else if (arg2 == 'text') {
          let page = arg3 ? parseInt(arg3) - 1 : 0;
          let key = `User$${username.toLowerCase()}*Text*${page}`;
          setCentrePanel(Profile, { username, page, feed:'Thoughts' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        } else {
          let page = arg2 ? parseInt(arg2) - 1 : 0;
          let key = `User$${username.toLowerCase()}*${page}`;
          setCentrePanel(Profile, { username, page, feed:'Main' }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        }
        setDocumentTitle(username?`${username} - $`:null);
      }

      else if (route == 'following') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'Following*' + page;
        setCentrePanel(Following, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('Following - $');
      }



      else if (route == 'hall-of-fame') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'HallOfFame*' + page;
        setCentrePanel(HallOfFame, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(LeaderboardPanel, {}, 'LeaderboardPanel');
        setDocumentTitle('Hall of fame - $');
      }



      else if (route == 'duel-arena') {
        let key = 'Duel*';
        setCentrePanel(DuelArena, { }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(DuelsPanel, {}, 'DuelsPanel');
        setDocumentTitle('Duel arena - $');
      }

      else if (route == 'admin') {
        if (arg == 'duel-arena') {
          let key = 'AdminDuelArena';
          setCentrePanel(DuelsAdmin, { }, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(DuelsAdminPanel, {}, 'DuelsAdminPanel');
        } else if (arg == 'analytics') {
          let key = 'AdminAnalytics';
          setCentrePanel(AdminAnalytics, {}, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        } else if (arg == 'query') {
          let key = 'Query';
          setCentrePanel(Query, {}, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        } else {
          let key = 'Admin';
          setCentrePanel(Admin, {}, key);
          setLeftPanel(LeftMenu, {}, 'LeftPanel');
          setRightPanel(null, {});
        }
        setDocumentTitle('Admin - $');
      }



      else if (route == 'contest') {
        let url = arg ? '/contest/' + arg : '';
        let page = arg2 ? parseInt(arg2) - 1 : 0;
        let key = 'Contest*' + url + '*' + page;
        setCentrePanel(Contest, { url, page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(ContestsPanel, {}, 'ContestsPanel');
        setDocumentTitle('Contest - $');
      }
        
      else if (route == 'messages') {
        let url = arg ? '/messages/' + arg : '';
        let key = 'Messages$' + url;
        setCentrePanel(Messages, { url }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(url ? MessagesPanel : null, {}, 'MessagesPanel');
        setDocumentTitle('Messages - $');
      }

      
      else if (route == 'subscription') {
        let key = 'Subscription';
        setCentrePanel(Subscription, {}, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Donate - $');
      }

      
      else if (route == 'glossary') {
        setCentrePanel(Glossary, {}, 'Glossary');
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Glossary - $');
      }
      
      else if (route == 'site-rules') {
        setCentrePanel(SiteRules, {}, 'SiteRules');
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Site rules - $');
      }
      else if (route == 'submission-rules') {
        setCentrePanel(SubmissionRules, {}, 'SubmissionRules');
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Submission rules - $');
      }
      else if (route == 'voting-rules') {
        setCentrePanel(VotingRules, {}, 'VotingRules');
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Voting rules - $');
      }


      else if (route == 'post') {
        let url = arg ? '/post/' + arg : '';
        let key = 'Post$' + url;
        setCentrePanel(PostSingle, { url }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('$');
      }
        


      else if (route == 'challenge') {
        let url = arg ? '/challenge/' + arg : '';
        let page = arg2 ? parseInt(arg2) - 1 : 0;
        let key = 'Challenge$' + url + '*' + page;
        // let key = 'Challenge$' + url;
        setCentrePanel(PostSingle, { url, page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(PostsPanel, {}, 'PostsPanel');
        setDocumentTitle('Challenge - $');
      }
      else if (route == 'duel') {
        let url = arg ? '/duel/' + arg : '';
        let key = 'Duel*' + url;
        // let key = 'Challenge$' + url;
        setCentrePanel(Duel, { url }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(DuelsPanel, {}, 'DuelsPanel');
        setDocumentTitle('Duel - $');
      }




      else if (route == 'search') {
        let term = arg ? arg : '';
        term = decodeURIComponent(term);
        let params;
        if (term.indexOf('=') > 0) {
          let p = {};
          let doParams = true;
          let a = term.split('&');
          let allowed = ['query', 'style', 'type', 'from', 'to', 'username'];
          a.forEach(s => {
            s = s.split('=');
            if (s.length == 2) {
              if (allowed.indexOf(s[0]) < 0) {
                doParams = false;
              } else {
                p[s[0]] = s[1];
              }
            } else {
              doParams = false;
            }
          })
          if (doParams) params = p;
        }
        let page = arg2 ? parseInt(arg2) - 1 : 0;
        let key = 'Search$' + term + '*' + page;
        setCentrePanel(Search, { term, page, params }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle(term ? `${term} search - $` : 'Search - $');
      }



      else if (route == 'glyphs') {
        let type = arg ? arg[0].toUpperCase() + arg.substr(1,arg.length-1) : 'All';
        let term1 = arg2 ? arg2.toUpperCase() : '';
        let term2 = arg3 ? arg3.toUpperCase() : '';
        let terms = [term1, term2];
        terms = terms.sort().map(t=>decodeURIComponent(t));
        term1 = terms[0];
        term2 = terms[1];
        let page = arg4 ? parseInt(arg4) - 1 : 0;
        let key = 'Glyphs*' + type + '*' + terms.join('/');
        setCentrePanel(Glyphs, { term1, term2, page, type }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(GlyphsPanel, {}, 'GlyphsPanel');
        setDocumentTitle(term1 && term2 ? `${term1}/${term2} glyphs - $` : 'Glyphs - $');
      }



      else if (route == 'adverts') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'Adverts*' + page;
        setCentrePanel(Adverts, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(user?AdvertsPanel:null, {}, 'AdvertsPanel');
        setDocumentTitle('Adverts - $');
      }



      else if (route == 'advert') {
        let username = arg ? arg : '';
        let key = 'Advert$' + username.toLowerCase();
        setCentrePanel(Advert, { username }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {}, 'SubscriptionPanel');
        // setRightPanel(SubscriptionPanel, {}, 'SubscriptionPanel');
        setDocumentTitle('Advert - $');
      }




      else if (route == 'performance') {
        let key = 'Performance';
        setCentrePanel(Performance, {}, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(LeaderboardPanel, {}, 'LeaderboardPanel');
        setDocumentTitle('Performance - $');
      }


        
        
        
      else if (route == 'tutorials') {
        let key = 'Tutorials';
        setCentrePanel(Tutorials, {}, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {}, '');
        setDocumentTitle('Tutorials - $');
      }
      else if (route == 'tutorial') {
        let URL = arg ? arg : '';
        let key = 'Tutorial$' + URL;
        setCentrePanel(Tutorial, { URL }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {}, '');
        setDocumentTitle('Tutorial - $');
      }
      
      
      
      else if (route == 'hearted') {
        let page = arg ? parseInt(arg) - 1 : 0;
        let key = 'Hearted*' + page;
        setCentrePanel(Hearted, { page }, key);
        setLeftPanel(LeftMenu, {}, 'LeftPanel');
        setRightPanel(null, {});
        setDocumentTitle('Hearted - $');
      }
      
      
      



      else if (route == 'v') {
        let id = arg;
        if (await verify(id)) {
          Toasts.create(Toast, { text: 'Account successfully verified!' });
        } else {
          Toasts.create(Toast, { text:'Verification unsuccessful. Maybe try again later!', type:'error' });
        }
        history.push('/posts');
      }



      else if (route == 'd') {
        let post = arg;
        let user = arg2;
        let { data } = await axios.post('/api/confirm-duel', {post,user})
        let { error, username } = data;
        if (username) {
          Modals.create(ModalMessage, { title: 'Good luck!', body: `Thank you ${username}, you will be participating in the next duel. You'll receive an email now or within the next few days with more details!` })
        } else {
          Modals.create(ModalMessage, { title: 'Error', body: error })
        }
        history.push('/duel-arena');
      }
      else if (route == 'dd') {
        let post = arg;
        let user = arg2;
        let { data } = await axios.post('/api/reject-duel', {post,user})
        let { error, username } = data;
        if (username) {
          Modals.create(ModalMessage, { title: 'Thank you', body: `Thank you ${username}, you will not participate in this duel. Your name is still enlisted, so you may be picked for the next duel. If you wish to unenlist, there is an option for that after closing this window.` })
        } else if (error) {
          Modals.create(ModalMessage, { title: 'Error', body: error })
        }
        history.push('/duel-arena');
      }


        
      else if (route == 'r') {
        let id = arg;
        let { data } = await axios.post('/api/reset', { id }); 
        let { error, username } = data;
        if (username) {
          Modals.create(ModalMessage, { title: 'Reset password', body: `Thank you ${username}, your password has been reset. Please check your email for your new password!` })
        } else {
          Modals.create(ModalMessage, { title: 'Error', body: error })
        }
        history.push('/posts');
      }

      

    })()
  }, [route, arg, arg2, arg3]);

  return <HistoryContext.Provider value={{ history, forceNavigate, navigate, noNavigate }}>
    <CentreKeyContext.Provider value={centreKey}>
      <ExpandLeftMenuContext.Provider value={{expandLeftMenu, setExpandLeftMenu, showRightPanel}}>
        <SocketContext.Provider value={socket}>
          <NotificationsContext.Provider value={{notifications, setNotifications, getNotifications}}>
            <ContestForceRenderContext.Provider value={[contestForce,contestForceRender]}>
              <CentreForceRenderContext.Provider value={[centreForce,centreForceRender]}>
                <div className={`App ${showRightPanel?'':'noRightPanel'}`} onClick={()=>setClicked(true)}>
                  <Nav />
                  <div className="app">
                    {!tablet?<div className="LeftPanel">
                      {LeftPanel.Render}
                    </div>:null}
                    <div className="CentreAndRightPanel">
                      <div className="CentrePanel">
                        <div id="TOP"/>
                        {CentrePanel.Render}
                      </div>
                      <div className="RightPanel">
                        {RightPanel.Render}
                      </div>
                    </div>
                  </div>
                  {tablet ? <ExpandingLeftPanel render={LeftPanel.Render} /> : null}
                </div>
              </CentreForceRenderContext.Provider>
            </ContestForceRenderContext.Provider>
          </NotificationsContext.Provider>
        </SocketContext.Provider>
      </ExpandLeftMenuContext.Provider>
    </CentreKeyContext.Provider>
  </HistoryContext.Provider>
})

function ExpandingLeftPanel({ render }) {
  let {expandLeftMenu, setExpandLeftMenu} = useContext(ExpandLeftMenuContext);
  let [renderScreenBehindLeftMenu, setRenderScreenBehindLeftMenu] = useState(false);
  let [slide, setSlide] = useState(false);
  let { doTimeout } = useTimeout();
  useEffect(() => {
    if (expandLeftMenu) {
      setRenderScreenBehindLeftMenu(true);
      setTimeout(() => setSlide(false),11);
    } else {
      setSlide(true);
      doTimeout(() => setRenderScreenBehindLeftMenu(false),0.5,'s')
    }
  }, [expandLeftMenu])
  let [w, setW] = useState(window.innerWidth);
  useEffect(() => {
    function resize() {
      setW(window.innerWidth);
    }
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  },[])
  return <>
    {renderScreenBehindLeftMenu ? <div className={`screenBehindLeftMenu ${expandLeftMenu ? '' : 'invisible'} ${slide ? 'slide' : ''}`} onClick={() => setExpandLeftMenu(false)} onMouseDown={() => setExpandLeftMenu(false)} onTouchStart={() => setExpandLeftMenu(false)}>{w>450?<Logo style={'WHITE'} scale={(w-14*16)/400} />:null}</div>:null}
    <div className={`LeftPanel fixed ${expandLeftMenu ? 'expanded' : ''}`}>
      {render}
    </div>
  </>
}

export default function App() {
  let modal = useModal("Modal", 0.2);
  let toast = useModal("Toast", 0.2);

  let { dark, setDark } = useDark();
  
  let titties = useAccount();

  return <AccountContext.Provider value={titties}>
    <UsersContext.Provider value={useUsers()}>
      <ModalContext.Provider value={modal}>
        <ToastContext.Provider value={toast}>
          <ResponsiveContext.Provider value={useResponsive()}>
            <VisibilityContext.Provider value={usePageVisible()}>
              <IdContext.Provider value={useId()}>
                <DarkContext.Provider value={{ dark, setDark }}>
                  <CurrencyContext.Provider value={useCurrency()}>
                    <SoundsContext.Provider value={{playPop: useSound('pop')}}>
                      <div className={`AmbigrDotAm ${dark?'DARK':'LIGHT'}`}>
                        <div className="BG"/>
                        <BrowserRouter>
                          <Switch>
                            <Route path='/e/:path/:lowres/:style/:width/:height' component={E} />
                            <Route path="/:route/:arg/:arg2/:arg3/:arg4" component={App2} />
                            <Route path="/:route/:arg/:arg2/:arg3" component={App2} />
                            <Route path="/:route/:arg/:arg2" component={App2} />
                            <Route path="/:route/:arg" component={App2} />
                            <Route path="/:route" component={App2}/>
                            <Route path="/" component={!titties.user?Home:App2}/>
                          </Switch>
                          {modal.Render}
                          {toast.Render}
                        </BrowserRouter>
                      </div>
                    </SoundsContext.Provider>
                  </CurrencyContext.Provider>
                </DarkContext.Provider>
              </IdContext.Provider>
            </VisibilityContext.Provider>
          </ResponsiveContext.Provider>
        </ToastContext.Provider>
      </ModalContext.Provider>
    </UsersContext.Provider>
  </AccountContext.Provider>
}