import React, {useState, useEffect, useContext} from 'react';
import Ambigram from './Ambigram';
import Comments from '../posts/Comments';
import FullScreenAmbigram from './FullScreenAmbigram';
import Word from './Word';
import Legibility from './Legibility';
import HeartButton from './HeartButton';
import GlyphTags from './GlyphTags';
import { ResponsiveContext, ModalContext, AccountContext, ToastContext, useAreYouSure, useDynamic } from '../../hooks';
import P from '../controls/P';
import Btn from '../controls/Btn.js';
import Dropdown from '../controls/Dropdown.js';
import Toast from '../modal/Toast';
import UploadAmbigram from '../forms/UploadAmbigram';
import EditGlyphs from '../forms/EditGlyphs';
import Versions from '../posts/Versions';
import Embed from './Embed';

import ModalMessage from '../modal/ModalMessage';

import { ImgDropdown, ImgZoom } from '../../pictures/SVG';

import axios from 'axios';
import AnimationSettings from '../forms/AnimationSettings.js';

export default function AmbigramPanel({ post, contest3, noWordBit }) {
    let { device, bigPhone } = useContext(ResponsiveContext);
    let Dynamic = useDynamic('post', post._id);
    let SetDeleted, SetLoading, SetPost, SetHidden;
    if (Dynamic) {
        SetDeleted = Dynamic.SetDeleted;
        SetLoading = Dynamic.SetLoading;
        SetPost = Dynamic.SetPost;
        SetHidden = Dynamic.SetHidden;
    }
    let { user, subbed, checkPermissions } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let Toasts = useContext(ToastContext);
    let [width, setWidth] = useState(600);
    let areYouSure = useAreYouSure();

    let me = user && (user.username == post.username);

    useEffect(() => {
        if (device == 'phone') {
            setWidth(400);
        } else if (device == 'tablet') {
            setWidth(500);
        } else setWidth(600);
    }, [device])

    let dropdownOptions = [];
    dropdownOptions.push({ title: 'Open image in new tab', link: "/api/pictures/" + post.picture.path, newTab: true });

    if (post._id) {
        dropdownOptions.push({ title: 'Open post in new tab', link: post.url, newTab: true });
        dropdownOptions.push({ title: 'Embed ambigram', callback: () => Modals.create(Embed, { post }) });
    }

    if (post.versions) { 
        dropdownOptions.push({ title: 'Version history', callback: () => Modals.create(Versions, { post }) });
    }

    async function deletePost() {
        if ((me || checkPermissions('Remove posts')) && await areYouSure("Are you sure you want to delete this post?")) {
            SetLoading(true);
            let {data} = await axios.post('/api/delete-post', { post, user });
            if (data) {
                SetDeleted(true);
            } else {
                Toasts.create(Toast, {preset:'server error'});
            }
            SetLoading(false);
            // doTimeout(()=>setPostLoading(false),0.3,'s');
        }
    }

    async function editPost() {
        Modals.create(UploadAmbigram, {
            // blockChangePicture: post.type == "Entry",
            post,
            blockChangePicture: post.type=="ChallengeEntry" || post.type=="DuelEntry" ? true : null,
            callback: async post => {
                let {data} = await axios.post('/api/edit-post', { post, user });
                if (data) {
                    SetPost(data);
                    return true;
                } else {
                    return false;
                }
            }
        })
    }

    if (SetDeleted) {
        if (me || checkPermissions('Edit posts')) dropdownOptions.push({title: 'Edit post', callback:editPost });
        if (me || checkPermissions('Remove posts')) dropdownOptions.push({ title: 'Delete post', callback: deletePost });
        if (post.feed) {
            if (me || checkPermissions('Move posts')) dropdownOptions.push({
                title: `Move to the ${post.feed == 'Sketches' ? 'main feed' : 'sketch area'}`, callback: async () => {
                let {data} = await axios.post('/api/move-post', { post, user, feed: post.feed == 'Sketches' ? 'Main' : 'Sketches' });
                if (data) {
                    SetPost(data);
                    Toasts.create(Toast, { text:`${me?'Your' : 'The'} post has been moved to the ${post.feed == 'Sketches' ? 'main feed' : 'sketch area'}` });
                    SetHidden(true);
                    return true;
                } else {
                    return false;
                }
            } });
        }
    }

    if (me || (user && user.admin)) {
        if (subbed || (user && user.admin)) {
            if (post.style.substr(0, 6) != 'Change' && post.style != 'Invert') {
                dropdownOptions.push({ title: 'Download animation', callback: () => Modals.create(AnimationSettings, {post} ) });
                // dropdownOptions.push({ title: 'Download animation', link: "/animation/" + post._id, newTab: true });
                // dropdownOptions.push({
                //     title: 'Download animation', callback: async () => {
                //         await axios.post('/api/create-animation', { user, post: post._id });
                //         Modals.create(ModalMessage, { title: "Donload animation", body: "Your animation is being rendered! You will be emailed a download link once the animation has been fully rendered - This might take a few minutes. The download link will expire after 24 hours." })
                //     }
                // });
            }
        } else {
            // dropdownOptions.push({ title: 'Download animation', link: "/animation/" + post.picture.path, newTab: true });
            dropdownOptions.push({ title: 'Download animation', callback: () => Modals.create(ModalMessage, { preset: 'Donate' }) });
        }
    }

    if (!contest3 && checkPermissions('Edit glyphs')) {
        dropdownOptions.push({title: 'Edit glyphs', callback: () => Modals.create(EditGlyphs, { post, callback: glyphs => {
            post.glyphs = glyphs;
            SetPost({ ...post });
        }}) });
    }

    if (user) {
        dropdownOptions.push({
            title: 'Stop notifications', callback: async () => {
                await axios.post('/api/stop-notifications', { user, post: {_id:post._id} });
            }
        });
    }

    if (checkPermissions('Hide posts')) {
        dropdownOptions.push({
            title: 'Hide post', callback: async () => {
                await axios.post('/api/hide-post', { user, post: {_id:post._id} })
                Toasts.create(Toast, { text: 'The post has been hidden!' });
                post.hidden = true;
                SetPost({ ...post });
            }
        });
    }

    return <div className="AmbigramPanel">
        {!noWordBit?<div className="wordBit">
            <div className="flex1">
                <Word word={post.word} />
            </div>
            {post.deleted ? null : <div className="dropdownBit">
                {post.versions?<div className="version">
                    Version {post.versions.length + 1}
                </div>:null}
                
                <Dropdown
                    key={post.versions?post.versions.length:0}
                    options={dropdownOptions}
                    btnProps={{className:"dropArrow",height:'1rem'}}
                ><ImgDropdown className="DARKGREY"/></Dropdown>
            </div>}
        </div>:null}
        <div>
            <Ambigram picture={post.picture} style={post.style} bounds={width}/>
        </div>
        {post.deleted?null:<div className="ambigramButtons">
            {post.legibility?<Legibility votes={post.legibility} post={post} />:null}
            {post.glyphs ? <GlyphTags post={post} contest3={contest3} />:null}
            {bigPhone ? null : <Btn
                className="zoom"
                onClick={() => Modals.create(FullScreenAmbigram,{picture:post.picture,style:post.style})}
            >
                <ImgZoom className="DARKGREY"/>
            </Btn>}
            {post.hearts?<HeartButton hearts={post.hearts} post={post} />:null}
        </div>}
        {post.message?<div className="message">
            <P noMarginBottom>{post.message}</P>
        </div> : null}
        {post.constructiveCriticism ? <div className="constructiveCriticism">Constructive criticism is welcome!</div> : null}
        {post.comments&&!post.deleted?<Comments post={post} />:null}
    </div>
}