import React, { useEffect, useRef, useState } from 'react';

export default function Slider({ display=true, value, setValue, min=0, max=1, disabled, width=200 }) {
    let [clicked, setClicked] = useState(false);
    let [x, setX] = useState((value - min) / (max - min));
    let ref = useRef();
    useEffect(() => {
        if (clicked) {
            function mouseUp() {
                setClicked(false);
            }
            document.addEventListener('mouseup', mouseUp)
            return ()=>document.removeEventListener('mouseup', mouseUp)
        }
    }, [clicked])

    useEffect(() => {
        setClicked(false);
    }, [disabled])
    
    useEffect(() => {
        setValue(min + x * (max - min));
    },[x])
    
    function getx(e) {
        let rect = ref.current.getBoundingClientRect();
        let x = (e.clientX - rect.left)/rect.width;
        setX(Math.max(Math.min(x,1),0))
    }

    return <div className={`Slider ${disabled ? 'disabled' : ''}`}>
        {display?x.toFixed(2):null}
        <div
            ref={ref}
            className="slider"
            onMouseDown={(e) => {
                if (!disabled) {
                    setClicked(true);
                    getx(e);
                }
            }}
            style={{ width }}
            onMouseMove={e => {
                if (clicked) {
                    getx(e);
                }
            }}
        >
            <div className="horizontalLine" />
            <div className="knob" style={{
                left:(width) * x - 3
            }}/>
        </div>
    </div>
}